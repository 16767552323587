import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';

import { PathsEnum } from './shared/enums/paths.interface';
import { SessionService } from './shared/services/auth/session.service';
import { LoginService } from './shared/services/login/login.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  public mobileQuery: MediaQueryList;
  public sideNavItems: any[] = [];
  public name: string;

  private _mobileQueryListener: () => void;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private media: MediaMatcher,
    private sessionService: SessionService,
    private router: Router,
    private loginService: LoginService
  ) {
    this.mobileQuery = this.media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => this.changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnInit(): void {
    this.getName();
    this.sideNavItems = this.createSideNavItems();
  }

  get isNotLOginRoute() {
    return !location.pathname.includes(PathsEnum.LOGIN);
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  private getName(): void {
    this.sessionService.getUser().subscribe(user => {
      this.name = this.splitName(user);
    })
  }

  private splitName(name: string): string {
    // console.log(name, "IBAG NAME" + new Date().getMinutes())
    if (name) {
      const formattedName =  name.split('.').map(letter => {
        return letter.charAt(0).toUpperCase() + letter.slice(1)
      }).join(' ');
      return formattedName;
    }
      
    return "";
  }
      

  private createSideNavItems(): any[] {
    return [
      { label: 'Home', routerLink: PathsEnum.HOME },
      { label: 'Base de Profissionais', routerLink: PathsEnum.PEOPLE },
      { label: 'Base de Projetos', routerLink: PathsEnum.PROJECT },
      { label: 'Base de Taxas', routerLink: PathsEnum.TAXES },
      { label: 'Rallocs', routerLink: PathsEnum.RALLOCS },
      { label: 'Auditoria Rallocs', routerLink: PathsEnum.AUDIT_RALLOCS },
      { label: 'Auditoria Financeira', routerLink: PathsEnum.FINANCIAL_AUDIT },
      { label: 'Controle Financeiro', routerLink: PathsEnum.FINANCIAL_CONTROL },
    ];
  }

  public logout(): void {
    // this.loginService.unsubscribeRefreshToken();
    this.sessionService.cleanSession();
    this.router.navigate([PathsEnum.LOGIN])
  }

}
