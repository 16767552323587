import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';

import { ILoginResponse } from '../../interfaces/login.interface';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  private _user: BehaviorSubject<string> = new BehaviorSubject('');

  constructor() {}

  public saveSession(dataSession: ILoginResponse) {
    localStorage.setItem(
      'KEY_ACCESS_TOKEN',
      JSON.stringify(dataSession)
    );
  }

  public cleanSession() {
    localStorage.clear();
  }

  public getSession() {
    return JSON.parse(localStorage.getItem('KEY_ACCESS_TOKEN') as string);
  }

  public setIsLogged(isLogged: boolean) {
    localStorage.setItem('IS_LOGGED', String(isLogged));
  }

  public isLogged() {
    return Boolean(localStorage.getItem('IS_LOGGED'))
  }

  public getUser() {
    // return of(localStorage.getItem('USER_NAME') as string);
    this._user.next(localStorage.getItem('USER_NAME') as string);
    return this._user.asObservable();
  }

  public setUser(user: string) {
    localStorage.setItem('USER_NAME', user);
    this._user.next(localStorage.getItem('USER_NAME') as string);
  }

}
