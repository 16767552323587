import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './shared/guard/auth.guard';
import { PathsEnum } from './shared/enums/paths.interface';

const routes: Routes = [
  {
    path: '',
    redirectTo: PathsEnum.LOGIN,
    pathMatch: 'full',
  },
  {
    path: PathsEnum.HOME,
    loadChildren: () => import("./modules/home/home.module").then(m => m.HomeModule),
    canActivate: [AuthGuard]
  },
  {
    path: PathsEnum.RALLOCS,
    loadChildren: () => import("./modules/rallocs-visualization/rallocs-visualization.module").then(m => m.RallocsVisualizationModule),
    canActivate: [AuthGuard]
  },
  {
    path: PathsEnum.PEOPLE,
    loadChildren: () => import("./modules/people/people.module").then(m => m.PeopleModule),
    canActivate: [AuthGuard]
  },
  {
    path: PathsEnum.AUDIT_RALLOCS,
    loadChildren: () => import("./modules/auditing-rallocs/auditing-rallocs.module").then(m => m.AuditingRallocsModule),
    canActivate: [AuthGuard]
  },
  {
    path: PathsEnum.FINANCIAL_AUDIT,
    loadChildren: () => import("./modules/file-upload-revenue/file-upload-revenue.module").then(m => m.FileUploadRevenueModule),
    canActivate: [AuthGuard]
  },
  {
    path: PathsEnum.PROJECT,
    loadChildren: () => import("./modules/projects/project.module").then(m => m.ProjectModule),
    canActivate: [AuthGuard]
  },
  {
    path: PathsEnum.FINANCIAL_CONTROL,
    loadChildren: () => import('./modules/financial-control/financial-control.module').then(m => m.FinancialControlModule),
    canActivate: [AuthGuard]
  },
  {
    path: PathsEnum.LOGIN,
    loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule),
  },
  {
    path: PathsEnum.TAXES,
    loadChildren: () => import('./modules/taxes/taxes.module').then(m => m.TaxesModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
