<div class="example-container" [class.example-is-mobile]="mobileQuery.matches">
  <mat-toolbar color="primary" class="main_toolbar">
    <div class="main__header-items">
      <button class="header_menu" mat-icon-button (click)="snav.toggle()"><mat-icon>menu</mat-icon></button>
      <button mat-button routerLink=""><h1 class="example-app-name">OTM</h1></button>
    </div>

    <div *ngIf="isNotLOginRoute" class="main__header-items">
      <h1>Olá, {{ name }}</h1>
      <button mat-icon-button matTooltip="Log Out" [matTooltipPosition]="'above'"
        matTooltipHideDelay="100" (click)="logout()">
        <mat-icon class="material-symbols-outlined">logout</mat-icon>
      </button>
    </div>

    
  </mat-toolbar>

  <mat-sidenav-container class="example-sidenav-container" [style.marginTop.px]="mobileQuery.matches ? 56 : 0">
    <mat-sidenav #snav mode="over" [fixedInViewport]="mobileQuery.matches" fixedTopGap="56">

      <mat-nav-list>
        <a mat-list-item *ngFor="let sideNavItem of sideNavItems" (click)="snav.toggle()" [routerLink]="sideNavItem.routerLink">
          {{ sideNavItem.label }}
        </a>
      </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content>
      <div class="border-forms">
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>

<app-loader></app-loader>