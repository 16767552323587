import { ActivatedRouteSnapshot, CanActivate, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { SessionService } from '../services/auth/session.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { PathsEnum } from '../enums/paths.interface';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  
  constructor(
    private authService: SessionService,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    
    if (this.authService.isLogged()) {
      return true
    }

    this.router.navigate([PathsEnum.LOGIN]);
    return false;
  }

}
