import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ILoginRequest, ILoginResponse, IRefreshToken } from '../../interfaces/login.interface';
import { Observable, interval, map, of, switchMap, tap, timer } from 'rxjs';
import { urlsService } from 'src/environments/urls-service';
import { SessionService } from '../auth/session.service';
import { Router } from '@angular/router';
import { PathsEnum } from '../../enums/paths.interface';
import { AlertsService } from '../../alerts/alerts.service';

@Injectable({ providedIn: 'root' })
export class LoginService {

  public TIME_FOR_REFRESH_TOKEN_AGAIN = 3600000;

  constructor(
    private http: HttpClient,
    private sessionService: SessionService,
    private router: Router,
    private alertsService: AlertsService,
  ) {
    if (this.sessionService.isLogged()) {
      this.refreshToken();
    }
  }

  public authenticate(request: ILoginRequest): Observable<ILoginResponse> {
    return this.http.post<ILoginResponse>(urlsService.LOGIN, request)
  }

  public refreshToken() {
    const { refreshToken } = this.sessionService.getSession();

    return interval((this.TIME_FOR_REFRESH_TOKEN_AGAIN)).pipe(
      switchMap(() => {
        return this.http.post<ILoginResponse>(urlsService.REFRESH_TOKEN, { refreshToken });
      })
    ).subscribe({
      next: token => {
        console.log(`CHAMADO O REFRESH TOKEN`)
        this.sessionService.saveSession(token)
      },
      error: (err: HttpErrorResponse) => {
        this.sessionService.cleanSession();
        this.alertsService.show({
          title: `Sessão expirada. Faça login novamente.`,
          text: `${err.error.message}`,
          icon: 'error',
          allowEscapeKey: false,
          allowOutsideClick: false,
        })
          .then((result) => {
            if (result.isConfirmed) {
              this.router.navigate([PathsEnum.LOGIN]);
            }
          });
      }
    })
  }

  public unsubscribeRefreshToken() {
    this.refreshToken();
  }


}
