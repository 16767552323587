import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PathsEnum } from 'src/app/shared/enums/paths.interface';
import { SessionService } from 'src/app/shared/services/auth/session.service';

@Injectable({providedIn: 'root'})
export class TokenInterceptor implements HttpInterceptor {

    constructor(private authService: SessionService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (!location.pathname.includes(PathsEnum.LOGIN)) {
            const { token } = this.authService.getSession();
            request = request.clone({
                setHeaders: {
                    'Authorization': `Bearer ${token}`
                }
            });
        }

        return next.handle(request); 
    }
    
}