import { environment } from "./environment";


export const urlsService = {
    RALLOCS: environment.HOST + 'ralloc/dados-custom',
    RALLOC_BY_YEAR: environment.HOST + 'ralloc/ano/{{ano}}',
    
    FILE_UPLOAD_CSV: environment.HOST + 'otm/carga-ralloc',
    FILE_UPLOAD_AUDIT: environment.HOST + 'otm/carga-lancamentoHora',
    PROFESSIONALS: environment.HOST + 'profissional',
    PROFESSIONAL_ACTIVE: environment.HOST + 'profissional/ativos',
    PROFESSIONAL_INACTIVE: environment.HOST + 'profissional/inativos',
    PROFESSIONAL_DISABLED: environment.HOST + 'profissional/desativar',
    PROFESSIONAL_ACTIVATE: environment.HOST + 'profissional/ativar',
    PROFESSIONAL_ALLOCATION: environment.HOST + 'alocacaoProfissional',
    PROVISIONING: environment.HOST + 'provisionamento',
    AUDITING_RALLOCS: environment.HOST + 'otm/auditoriaRalloc',
    RELATORIES_PDF: environment.HOST + 'otm/auditoriaRallocPdf',
    RELATORIES_EXCEL: environment.HOST + 'otm/auditoriaRallocExcel',
    FILE_UPLOAD_REVENUE: environment.HOST + 'reconhecimento_receita/upload',
    FILE_UPLOAD_REVENUE_LIST_FISCAL_YEAR: environment.HOST + 'reconhecimento_receita/list_year',
    FILE_UPLOAD_REVENUE_FISCAL_YEAR: environment.HOST + 'reconhecimento_receita/fiscal_year',
    FILE_UPLOAD_REVENUE_DETAIL: environment.HOST + 'reconhecimento_receita/month_year',
    STATUS_PROFISSIONAL: environment.HOST + 'statusProfissional',
    PROFISSIONAL_GERENTE: environment.HOST + 'profissional/gerente',
    PROJETO: environment.HOST + 'projeto',
    ESCRITORIO: environment.HOST + 'escritorio',
    EMPRESA: environment.HOST + 'empresa',
    PROFISSIONAL_DETALHE: environment.HOST + 'profissional/detalhe',
    AUDITORIA_RALLOC_ANO_MES: environment.HOST + 'otm/auditoriaRalloc/{{ano}}/{{mes}}',
    AUDITORIA_RALLOC_PERIODO: environment.HOST + 'otm/auditoriaRallocPeriodo/{{de}}/{{ate}}',
    CONTROLE_FINANCEIRO: environment.HOST + 'controleFinanceiro',
    CONTROLE_FINANCEIRO_POR_ID: environment.HOST + 'controleFinanceiro/{{id}}',
    CONTROLE_FINANCEIRO_POR_WBS_E_DATA: environment.HOST + 'controleFinanceiro/{{wbs}}/{{anoFiscal}}',
    CONTROLE_FINANCEIRO_COPY_NEXT_MONTH: environment.HOST + 'controleFinanceiro/{{wbs}}/{{ano}}/{{mes}}',
    CONTROLE_FINANCEIRO_UPLOAD: environment.HOST + 'controleFinanceiro/upload',

    CONTROLE_FINANCEIRO_DETALHE_ALL: `${environment.HOST}controlefinanceirodetail/all`,

    TAXAS: environment.HOST + 'taxas',
    TAXAS_UPLOAD: environment.HOST + 'taxas/upload',
    RECONHECIMENTO_RECEITA_WBS_ANO_MES: environment.HOST + 'reconhecimentoReceita/{{wbsCod}}/{{ano}}-{{mes}}/{{anoAte}}-{{mesAte}}',
    LOGIN: `${environment.HOST}auth`,
    REFRESH_TOKEN: `${environment.HOST}auth/refresh-token`,
}