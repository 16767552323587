import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  constructor(
    private ngxSpinnerService: NgxSpinnerService,
  ) { }

  public show(): void {
    this.ngxSpinnerService.show();
  }

  public hide(): void {
    this.ngxSpinnerService.hide();
  }

}
